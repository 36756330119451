$primary-text-color: #05122d;
$selected-text-color: #00fcb4;

.ant-btn.data-table-filter {
  width: fit-content;
  background-color: #fff;
  border: 1px solid $primary-text-color;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: $primary-text-color;
  border-radius: 5px;
  text-align: left;
  padding: 4px 16px;

  .data-table-filter-arrow {
    font-size: 13px;
    margin-left: 20px;
  }
}

.ant-btn.data-table-filter.data-table-filter-is-selected {
  border: 2px solid $selected-text-color !important;
}

.ant-popover-placement-bottomLeft .ant-popover-arrow {
  display: none;
}
