@import "../../css/breakpoints";

.navbar-dropdown {
  background-color: white;
  border-color: white;

  a.navbar-item:not(.is-active, .is-selected) {
    background-color: white;
    color: black;
  }

  a.navbar-item {
    background-color: white;
    color: black;
  }
}


.login-modal {
  width: 350px;
  padding: 15px;

  .login-box {
    width: 100%;

    hr {
      margin: 0.8rem 0;
    }

    .forgot-password-link {
      font-weight: normal;
      float: right;
      color: #b3b0b0;
      cursor: pointer;

      &:hover {
        color: #40a9ff;
      }
    }
  }
}

.navbar-link .all-ecosystems {
  color: #ffffff;
}

#brand-icon {
  min-height: 80px;
}

.navbar.is-link .navbar-item.has-dropdown:focus .navbar-link,
.navbar.is-link .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link,
.navbar-link.not-hover-color:hover {
  background-color: transparent !important;
}

.navbar {
  .navbar-brand-text {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #00fcb4;
  }

  .ant-space-item, .navbar-start {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
}

@include media-breakpoint-down(lg) {
  .navbar {
    .navbar-brand {
      div.ecosystem-menu-mobile {
        display: flex;
      }
    }

    .navbar-menu {
      .ecosystem-menu {
        display: none;
      }
    }

    .ecosystem-menu-mobile {
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      .navbar-dropdown {
        background-color: white;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top: 2px solid #dbdbdb;
        box-shadow: 0 8px 8px rgba(10, 10, 10, 0.1);
        display: none;
        font-size: 0.875rem;
        left: 0;
        min-width: 100%;
        position: absolute;
        top: 100%;
        z-index: 20;
      }

      .navbar-dropdown-show {
        display: block;
      }
    }

    .navbar-start {
      .navbar-item {
        a.has-text-white {
          color: #4a4a4a !important;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar {
    .navbar-brand {
      div.ecosystem-menu-mobile {
        display: none;
      }
    }

    .navbar-menu {
      .ecosystem-menu {
        display: flex;
      }
    }
  }
}
