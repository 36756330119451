@import "../../css/colors";
@import "../../css/breakpoints";

$primary-color: #05122d;
$active-state-color: #00fcb4;

.main-filter-wrapper {
  flex-grow: 1;

  .ant-btn.ant-btn-default.main-filter {
    border: 2px solid $primary-color !important;
    background-color: transparent !important;
    border-radius: 5px;
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;

    span {
      color: $primary-color;
      background-color: transparent;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
    }
  }

  .main-filter.is-checked.ant-btn {
    border: 2px solid $active-state-color !important;
  }

  .main-filter-popover.ant-popover-placement-bottomLeft {
    padding-top: 0px;
  }
}

@include media-breakpoint-up(md) {
  .main-filter-wrapper {
    flex-grow: 0;
  }
}
