.gg-notification {
  display:none;
  position: absolute;
  left: 45%;
  top: 20px;
  padding: 10px;
  z-index: 9999;
  background-color: white;
  border-radius: 5px;
  font-size: 18px;

  &.open {
    display: block;
  }
}
