$white: #ffffff;
$blue: rgb(14, 100, 161);
$blue-lighter: rgb(39, 110, 162);
$green: rgb(36, 190, 160);
$yellow: rgb(255, 221, 87);
$red: rgb(255, 56, 96);
$grey: rgb(87, 87, 90);
$grey-dark: rgb(56, 51, 51);
$grey-darker: rgb(35, 43, 53);
$grey-lighter: rgb(185, 185, 185);
$grey-bg: rgb(240, 244, 247);
$blue-light: #dae8f5;
$blue-blood: #093159;
$green-light: #25bea0;
$gray: #808080;

// Bulma colors
$primary: $green;
$link: $blue;
$info: $blue;
$success: $green;
$warning: $yellow;
$danger: $red;
$dark: $grey-dark;
$text: $grey-darker;
$title-color: $grey;

$navbar-background-color: transparent;
$dropdown-item-color: $blue;
