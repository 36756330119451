$selected-color: #00fcb4;

.text-filter-input {
  width: 150px;
  border-radius: 5px;
}

.text-filter-title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #093159;
}

.ant-btn.text-filter-button {
  background-color: #093159;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
}

.range-filter-popover-content {
  .ant-radio {
    &:hover {
      .ant-radio-inner {
        border: 1px solid $selected-color;
      }
    }
  }
  .ant-radio-input {
    &:focus {
      + {
        .ant-radio-inner {
          border: 1px solid $selected-color;
        }
      }
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border: 1px solid $selected-color;

      &:after {
        border: 1px solid $selected-color;
        background-color: $selected-color;
      }
    }
  }
}
