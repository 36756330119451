$primary-color: #05122d;

.reset-password-modal {
  font-family: "Inter", sans-serif;

  .ant-modal-title {
    p {
      font-family: "Manrope", sans-serif;
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    .ant-btn.ant-btn-default {
      border: 1px solid #77b8e0;
      color: $primary-color;
      width: 100%;
      border: 1.5px solid #05122d;
      border-radius: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #05122d;
      }
    }

    .ant-btn.ant-btn-primary {
      background: #00fcb4;
      border-radius: 5px;
      width: 100%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;
      border: 1.5px solid #00fcb4;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #00fcb4;
      }
    }
  }
}
