.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title h2 {
  margin-top: 12px;
}

.modal-icon {
  width: 60px;
}

.form-group {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  padding: 10px;
}

.form-group label {
  margin-top: 8px;
  padding-right: 10px;
}

.input {
  display: flex;
  flex: 1;
}

.vertical-tag-header-text {
  font-size: 16pt;
}

.btn-add-tag.ant-btn.ant-btn-default {
  border: 2px solid #05122d;
  border-radius: 5px;
  background-color: transparent;
  font-size: 16px;

  span {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #05122d;
  }
}

.tag-card.ant-card.ant-card-bordered {
  border-radius: 10px;
  border: 1px solid #b9b9b9;

  .tag-card-content {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
  }

  .ant-card-head {
    border-bottom: none;

    .ant-card-head-title {
      font-family: "Manrope", sans-serif;
      font-size: 20px;
      border-bottom: 2px solid #b9b9b9;
    }

    .ant-card-extra {
      cursor: pointer;
    }
  }

  .tag-page-count {
    color: #25bea0;
  }
}

.input-error {
  border: 1px solid red;
}

.error-message {
    color: red;
}