.custom-revision-legend-value {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 18pt;
  color: #05122d;
}

.custom-revision-legend-label {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  color: #a5acb4;
  width: 100px;
}
