@import "../../css/breakpoints";

.section {
  .box {
    background-color: white;
    .subtitle {
      font-family: "Manrope", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 18px;
      color: #000;
    }
    .content {
      font-family: "Inter", sans-serif;

      .create-acc-btn {
        padding: 12px 20px;
        background: #00fcb4;
        border-radius: 5px;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        color: #000000;
      }
    }
  }
}

@include media-breakpoint-only(xs) {
  .box {
    min-width: 300px;
  }
}
