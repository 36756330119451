.ecosystem-board-content-no-website {
  padding-top: 60px;
  padding-bottom: 60px;

  .no-website-message {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 23px;
    color: #05122d;
    margin-bottom: 32px;
  }
}
