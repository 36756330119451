@import "../../../css/_breakpoints.scss";

$primary-color: #05122d;
$primary-border-color: #05122d;

.add-ecosystem-btn.ant-btn {
  border: 2px solid $primary-border-color;
  color: $primary-color;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  height: 44px;
}

.add-ecosystem-btn.ant-btn:hover {
  color: #40a9ff;
  border-color: #40a9ff;
  background: #fff;
}

.add-ecosystem-modal {
  .ant-modal-body {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $primary-color;
  }

  .modal-item {
    margin-bottom: 40px;
  }

  .ecosystem-name.ant-input {
    &::placeholder {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: $primary-color;
    }

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: $primary-color;
    padding: 16px;
    border-radius: 5px;
  }

  .ecosystem, .industry, .currency {
    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: $primary-color;
      margin-bottom: 16px;
    }

    .modal-dropdown {
      width: 143px;
      border: 1px solid $primary-border-color;
      border-radius: 5px;
      width: 100%;

      .ant-select-selector {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: $primary-color;
      }
    }
  }

  .vertical-tag {
    background-color: #fff;
    width: 100%;

    .vertical-tag-title {
      font-family: "Inter";
      font-size: 15px;
      color: $primary-color;
      font-weight: 600;
    }

    .vertical-tag-icon {
      background: #ffffff;
      border: 0.1px solid #d9d9d9;
      border-radius: 3px;
    }

    .vertical-tag-choice {
      padding: 24px 16px;
      border: 0.2px solid #ededed;
      border-radius: 5px;

      .ant-space-item {
        margin-bottom: 10px;

        .vertical-tag-item {
          cursor: pointer;
          color: $primary-color;
          padding: 3px 10px;
          background-color: #fff;
          border: 0.2px solid #ededed;
          border-radius: 30px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          padding: 8px 16px;
        }

        .vertical-tag-item.vertical-tag-item-selected {
          background-color: $primary-border-color;
          border: 0.2px solid #000000;
          border-radius: 30px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: #f3fffb;
          padding: 8px 16px;
        }
      }
    }
  }

  .ant-modal-footer {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;

    .ant-btn.ant-btn-default {
      border: 1px solid #77b8e0;
      color: $primary-color;
      width: 200px;
      border: 1.5px solid #05122d;
      border-radius: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #05122d;
      }
    }

    .ant-btn.ant-btn-primary {
      background: #00fcb4;
      border-radius: 5px;
      width: 200px;
      margin-left: 20px !important;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;
      border: 1.5px solid #00fcb4;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #00fcb4;
      }
    }
  }
}

.ant-dropdown.add-ecosystem-modal-item-dropdown {
  .ant-dropdown-menu-title-content {
    font-family: "Open Sans", sans-serif;
    color: #093159;
    font-size: 16px;
  }
}

.industry-dropdown-item {
  .ant-select-item-option-content {
    font-family: "Open Sans", sans-serif;
    color: $primary-color;
    font-size: 16px;
    width: 100%;
    border-radius: 5px;
  }
}

.add-ecosystem-modal-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #05122d;
  padding-top: 10px;
  margin-bottom: 0;
}

.sub-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  color: #05122d;
  padding-top: 10px;
  font-size: 13px;
  line-height: 18px;
}

@include media-breakpoint-only(xs) {
  .add-ecosystem-modal {
    .ant-modal-footer {
      .ant-btn.ant-btn-default {
        width: 100%;
        margin-bottom: 10px;
      }

      .ant-btn.ant-btn-primary {
        width: 100%;
      }
    }
  }
}
