.sub-header {
    background: rgba(255, 245, 0, 0.1);

    .container {
      h1 {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 44px;
        color: #000000 !important;
        margin-bottom: 24px;
      }
    }

    .nav-subheader {
        display: flex;
        align-items: flex-start;

        .nav-subheader-link {
            padding-bottom: 0.4rem;
            margin-right: 1rem;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #000000;

            &.is-active {
                border-bottom: 2px solid #05122D;

                &:hover {
                    border-bottom: 2px solid #05122D;
                }
            }

            &:hover {
                border-bottom: 2px solid #05122D;
            }
        }
    }
}

.breadcrumbs {
    background-color: #f9f9f9;
    border-bottom: 1px solid #dbdbdb;

    ul {
        align-items: center;
    }

    ul li a:link {
        white-space: break-spaces;
    }

    .breadcrumb ul li .navbar-dropdown a {
        padding: 0.375rem 1rem;
        white-space: nowrap;
        color: #363636;
        justify-content: flex-start;
        cursor: pointer;
        pointer-events: all;
    }

    .breadcrumb ul li.is-active .navbar-link {
        pointer-events: none;
    }

    .breadcrumb .navbar-item .navbar-link {
        padding-top: 0;
        padding-bottom: 0;

        a.navbar-item:hover {
            color: #363636;
        }
    }

    .breadcrumb .navbar-link:not(.is-arrowless) {
        padding-right: 1.6rem;
    }
}
