.ecosystem-board-content-empty {
  .empty-ecosystem-message {
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #093159;
    font-size: 16px;
    margin-bottom: 30px;
  }

  .new-ecosystem-btn {
    border: 2px solid #6497ca;
    background-color: #fff;
    color: #093159;
    font-weight: bold;
    border-radius: 5px;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  }
}
