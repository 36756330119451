.sub-header {
    background-color: #659eef;
    color: #ffffff;

    .nav-subheader {
        display: flex;
        align-items: flex-start;

        .nav-subheader-link {
            color: #ffffff;
            padding-bottom: 0.4rem;
            margin-right: 1rem;

            &.is-active {
                border-bottom: 5px solid #00d1b2;

                &:hover {
                    border-bottom: 5px solid #00d1b2;
                }
            }

            &:hover {
                border-bottom: 5px solid #ffffff;
            }
        }
    }
}

.breadcrumbs {
    background-color: #f9f9f9;
    border-bottom: 1px solid #dbdbdb;

    ul {
        align-items: center;
    }

    ul li a:link {
        white-space: break-spaces;
    }

    .breadcrumb ul li .navbar-dropdown a {
        padding: 0.375rem 1rem;
        white-space: nowrap;
        color: #363636;
        justify-content: flex-start;
        cursor: pointer;
        pointer-events: all;
    }

    .breadcrumb ul li.is-active .navbar-link {
        pointer-events: none;
    }

    .breadcrumb .navbar-item .navbar-link {
        padding-top: 0;
        padding-bottom: 0;

        a.navbar-item:hover {
            color: #363636;
        }
    }

    .breadcrumb .navbar-link:not(.is-arrowless) {
        padding-right: 1.6rem;
    }
}