@import "../../../css/colors";

.product-table-wrapper {
  padding: 10px;
  background-color: white;
  border: 1px solid $grey-lighter;

  .change-down {
    color: #c034a7;
  }
  .change-up {
    color: #25bea0;
  }

  .ant-table-ping-left {
    .ant-table-cell-fix-left-first {
      &::after {
        box-shadow: none;
      }
    }
    .ant-table-cell-fix-left-last {
      &::after {
        box-shadow: none;
      }
    }
  }

  thead.ant-table-thead {
    th.ant-table-cell {
      font-family: "Manrope" !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 19px !important;
      color: #05122d !important;

      .table-header-title {
        &.font-size-11 {
          font-size: 11px;
        }

        &.font-size-13 {
          font-size: 13px;
        }

        &.font-size-15 {
          font-size: 15px;
        }

        &.font-size-17 {
          font-size: 17px;
        }

        &.font-size-19 {
          font-size: 19px;
        }
      }
    }
  }

  .table {
    .ant-table {
      .ant-table-container {
        &::before {
          width: 0;
        }
        &::after {
          width: 0;
        }
        .ant-table-content {
          &::-webkit-scrollbar {
            height: 10px;
          }
          &::-webkit-scrollbar-track {
            background: #dae4e8;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb {
            background: #093159;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #093159;
          }
        }
      }
    }

    .ant-table-thead {
      > tr {
        > th.ant-table-cell {
          background-color: #f7faf9 !important;
        }
      }

      tr {
        .ant-table-cell {
          border-bottom: 0px;

          .ant-table-column-sorter-up.active,
          .ant-table-column-sorter-down.active {
            color: #093159;
          }

          .ant-table-column-sorter-up,
          .ant-table-column-sorter-down {
            color: #b0bdf2;
          }
        }
      }

      > tr {
        > th {
          &:not(:last-child) {
            &:not(.ant-table-selection-column) {
              &:not(.ant-table-row-expand-icon-cell) {
                &:not([colspan]) {
                  &::before {
                    width: 0px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      td.ant-table-cell {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        color: #05122d;

        &.font-size-10 {
          font-size: 10px;
        }

        &.font-size-12 {
          font-size: 12px;
        }

        &.font-size-14 {
          font-size: 14px;
        }

        &.font-size-16 {
          font-size: 16px;
        }

        &.font-size-18 {
          font-size: 18px;
        }
      }

      .cell-link {
        color: #5a8efa;
      }

      .ant-table-cell.ant-table-cell-fix-left-last.ant-table-cell-fix-left {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        border-bottom: 3px solid #f5f6fa;
        padding: 16px;
        border-right: 3px solid #f5f6fa;
      }
      .ant-table-cell.ant-table-selection-column {
        padding: 16px 8px;
        left: 0px;
        text-align: left;
      }
    }
  }
}
