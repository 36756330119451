$primary-color: #093159;
$primary-border-color: #05122d;
$radio-color: #05122d;

.ant-input.modal-item,
.modal-item {
  margin-bottom: 30px;
}

.modal-item.modal-input.ant-input {
  border-radius: 5px;
}

.ant-modal-header {
  .ant-modal-title {
    font-family: "Manrope", sans-serif;
    font-weight: bold;
    color: $primary-color;
    font-size: 24px;
  }
}

.modal-item-heading {
  font-family: "Manrope", sans-serif;
  font-weight: bold;
  color: $primary-color;
  font-size: 18px;
}

.modal-radio {
  .modal-radio-choice {
    .ant-radio.ant-radio-checked {
      .ant-radio-inner {
        border-color: $radio-color;

        &:after {
          background-color: $radio-color;
        }
      }
    }

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }
  }
}

.modal-dropdown {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #05122d;
  border: 1px solid $primary-border-color;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;

  .ant-select-selector {
    color: $primary-color;
    font-size: 16px;
  }

  .ant-select-arrow {
    color: $primary-color;
  }
}

.modal-dropdown-item {
  .ant-select-item-option-content {
    font-family: "Inter", sans-serif;
    color: $primary-color;
    font-size: 16px;
    width: 100%;
    border-radius: 5px;
  }
}

.modal-dropdown.border-red {
  .ant-select-selector {
    border: 2px solid red;
  }
}
