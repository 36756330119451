@import "../../../css/colors.scss";
@import "../../../css/breakpoints";

.main-product-availability-chart-container {
  border: 1px solid $grey-lighter;
  background-color: $white;
  margin-top: 16px;
  border-radius: 5px;
  padding: 0px 0px 60px 0px;

  .main-chart {
    height: 600px;

    .recharts-surface {
      max-height: 500px;
      margin-left: -20px;
    }

    .recharts-legend-wrapper {
      max-width: 1100px;
      margin-left: 40px;
    }
  }

  .main-chart-filter {
    margin: 25px 50px 60px 50px;
  }
}

@include media-breakpoint-up(lg) {
  .main-product-availability-chart-container {
    border: 1px solid $grey-lighter;
    background-color: $white;
    margin-top: 16px;
    border-radius: 5px;
    padding: 0px 0px 60px 0px;

    .main-chart {
      height: 600px;

      .recharts-surface {
        max-height: 500px;
      }

      .recharts-legend-wrapper {
        max-width: 1100px;
        padding-left: 50px;
      }
    }

    .main-chart-filter {
      margin: 25px 50px 60px 50px;
    }
  }
}
