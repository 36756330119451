$selected-color: #00fcb4;

.font-size-change-popover-content {
  .ant-radio {
    &:hover {
      .ant-radio-inner {
        border: 1px solid $selected-color;
      }
    }
  }
  .ant-radio-input {
    &:focus {
      + {
        .ant-radio-inner {
          border: 1px solid $selected-color;
        }
      }
    }
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border: 1px solid $selected-color;

      &:after {
        border: 1px solid $selected-color;
        background-color: $selected-color;
      }
    }
  }
}
