@import "../../../css/breakpoints";

$primary-button-color: #00fcb4;
$primary-color: #05122d;
$primary-border-color: #6497ca;

.add-website-btn.ant-btn {
  background-color: $primary-button-color;
  color: #000;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  height: 44px;
}

.add-website-btn.ant-btn:hover {
  color: #40a9ff;
  border-color: #40a9ff;
  background: #fff;
}

.website.ant-input {
  padding: 16px;
  color: $primary-color;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  &::placeholder {
    color: $primary-color;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
}

.add-website-modal-title {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #05122d;
  margin-bottom: 10px;
}

.add-website-modal {
  .modal-label {
    color: $primary-color;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
  .modal-item-heading {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: $primary-color;
  }
  .modal-item {
    .ant-select-selection-item {
      font-size: 13px;
    }
  }

  .ant-modal-footer {
    padding-right: 24px;
    padding-bottom: 24px;
    .ant-btn.ant-btn-default {
      color: $primary-color;
      width: 200px;
      border: 1.5px solid #05122d;
      border-radius: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #05122d;
      }
    }

    .ant-btn.ant-btn-primary {
      background: #00fcb4;
      border-radius: 5px;
      width: 200px;
      margin-left: 20px !important;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;
      border: 1.5px solid #00fcb4;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #00fcb4;
      }
    }
  }

  .notice {
    .notice-text {
      color: $primary-color;
      font-family: "Inter";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
    }
  }
}

@include media-breakpoint-only(xs) {
  .add-website-modal {
    .ant-modal-footer {
      .ant-btn.ant-btn-default {
        width: 100%;
        margin-bottom: 10px;
      }

      .ant-btn.ant-btn-primary {
        width: 100%;
        margin-left: 0px !important;
      }
    }
  }
}
