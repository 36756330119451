.main-chart-total-number {
  font-family: "Inter", sans-serif;
  font-weight: bold;
  font-size: 18pt;
  color: #05122d;
}

.main-chart-total-text {
  font-family: "Inter", sans-serif;
  font-size: 12pt;
  color: #a5acb4;
  width: 100px;
}
