.home-content {
    background-color: #f0f4f7;

    .signup-box {
        margin-top:30px;
        margin-bottom: 30px;
        background-color: #ffffff;
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;

        & > .columns {
            padding: 15px;
        }

        .left-column {
            border-right: 1px solid #dee2e6;
        }

        .right-column {
            padding-left: 25px;
        }

        .signup-form-container {
            margin-top: 20px;

            .terms-privacy {
                margin-bottom: 20px;
            }
        }
    }
}