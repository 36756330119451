@import "../../css/colors";
$active-state-color: #00fcb4;

.main-filter-content-title {
  font-weight: bold;
  color: $grey;
}

.main-filter-content-value.ant-checkbox-wrapper {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $active-state-color;
    border-color: $active-state-color;
  }
  &:hover {
    .ant-checkbox-inner {
      border-color: $active-state-color;
    }
  }
  .ant-checkbox-checked {
    &:after {
      border-color: $active-state-color;
    }
  }
  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: $active-state-color;
      }
    }
  }
  .ant-checkbox-input {
    &:focus + .ant-checkbox-inner {
      border-color: $active-state-color;
    }
  }
}
