.traffic-chart-tooltip {
  background-color: #05122d;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 5px;
  border-radius: 5px;
  text-align: center;
  line-height: 1;
}
