@import "../../css/_colors";
$primary-color: #05122d;

.date-picker-select.ant-select {
  .ant-select-selector {
    background-color: $white !important;
    border-radius: 5px !important;
    border: 1px solid $primary-color !important;
    color: $primary-color !important;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
  .ant-select-arrow {
    color: $primary-color;
  }
}

.date-picker-select.ant-select.ant-select-single.ant-select-show-arrow {
  margin-right: 10px;
}

.date-picker-value.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: $blue-light;
  color: $blue-blood;
  font-weight: normal;
}
