$select-item-color: #05122d;

.website-page-detail-chart {
  width: 100%;
  margin: 25px 0;
  padding: 50px 20px;
  min-height: 600px;

  .website-page-detail-chart-select {
    margin-bottom: 50px;
    .ant-select-selector {
      .ant-select-selection-item {
        font-family: "Manrope", sans-serif;
        font-weight: 600;
        font-size: 24px;
        margin-right: 12px;
        color: $select-item-color;
      }
    }

    .ant-select-arrow {
      color: $select-item-color;
    }
  }

  .website-page-detail-chart-date {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #7e8e9f;
    margin-top: 5px;
  }
}

@media (min-width: 992px) {
  .website-page-detail-chart {
    padding: 50px 20px 50px 40px;

    .legend {
      margin-left: 100px;
    }
  }
}
