$primary-color: #05122d;
$primary-border-color: #05122d;

.message-box-modal {
  .ant-modal-header {
    padding: 16px 24px;

    .ant-modal-title {
      padding-bottom: 20px;
      font-family: "Manrope", sans-serif;
      font-weight: bold;
      color: $primary-color;
      font-size: 24px;
    }
  }

  .ant-modal-footer {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    .ant-btn.ant-btn-default {
      border: 1px solid #77b8e0;
      color: $primary-color;
      width: 100%;
      border: 1.5px solid #05122d;
      border-radius: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #05122d;
      }
    }
  }
}
