.datanoodle-footer {
  padding: 30px;
  background-color: rgba(5, 18, 45, 0.8);
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  .footer-links {
    margin: 10px 0;
    color: #f3fffb;

    .button {
      text-decoration: none;
      color: #f3fffb;

      &:hover {
        background-color: transparent;
        border-bottom: 1px solid #fff500;
      }
    }
  }
}

.copyright-statement {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 30px;
  color: #ffffff;
  background-color: rgba(5, 18, 45, 0.9);
}
