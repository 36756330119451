$primary-button-color: #00fcb4;
$primary-color: #05122d;
$primary-border-color: #6497ca;

.gg-integration-modal-wrap {
  .ant-modal-header {
    .ant-modal-title {
      font-family: "Manrope";
      font-style: normal;
      font-weight: 500;
      font-size: 26px;
      line-height: 36px;
      color: #05122d;
      padding-bottom: 10px;
    }
  }

  .ant-modal-body {
    .signin-gg-btn {
      padding: 12px 15px;
      height: 42px;
      color: $primary-color;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      border-radius: 5px;
      width: 100%;
      margin-bottom: 30px;

      &::placeholder {
        color: $primary-color;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
      }
    }
  }

  .ant-modal-footer {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    .ant-btn.ant-btn-default,
    .ant-btn.ant-btn-primary {
      color: $primary-color;
      width: 100%;
      border: 1.5px solid #05122d;
      border-radius: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #05122d;
      }
    }

    .ant-btn.ant-btn-primary {
      background-color: transparent;
    }
  }

  .notice {
    .notice-text {
      color: $primary-color;
      font-family: "Inter";
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
    }
  }
}

.ant-modal {
  &.integration {
    .ant-btn.ant-btn-primary {
      display: none;
    }
  }

  &.integration-message {
    .ant-btn.ant-btn-default {
      display: none;
    }
  }
}

.ga-account-select {
  width: 100%;
}

.ga-integration-message {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: #05122d;
  text-align: center;

  .ga-integration-success-message {
    font-weight: bold;
  }
}
