@import "../../css/breakpoints";

.content-container {
  width: 300px;
  font-family: "Inter, san-serif";
}

@include media-breakpoint-up(md) {
  .content-container {
    width: 600px;
  }
}

@include media-breakpoint-up(xl) {
  .content-container {
    width: 900px;
  }
}
