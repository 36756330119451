@import "../../../../css//breakpoints";

.website-table-header {
  margin-bottom: 15px;

  .assign-vertical-btn-col {
    text-align: left;
  }
}

@include media-breakpoint-up(sm) {
  .website-table-header {
    .assign-vertical-btn-col {
      text-align: right;
    }
  }
}
