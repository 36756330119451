.website-table-of-ecosystem {
  .ant-table-thead {
    .ant-table-cell {
      color: #093159;
      font-weight: bold;
      font-family: "Roboto", sans-serif;
    }

    > tr {
      > th {
        border-bottom: none;
        &:not(:last-child) {
          &:not(.ant-table-selection-column) {
            &:not(.ant-table-row-expand-icon-cell) {
              &:not([colspan]) {
                &::before {
                  width: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell {
        border-bottom: 3px solid #f5f6fa;
      }

      &:last-child {
        .ant-table-cell {
          border-bottom: none;
        }
      }
    }
  }

  .ant-table-cell {
    font-family: "Roboto", sans-serif;
    font-size: 12pt;

    .a {
      color: #3984ec;
    }

    .ant-btn.website-table-dropdown-btn {
      color: #093159;
      border: 2px solid #093159;
      border-radius: 5px;
      padding-left: 5px;
    }

    .website-table-select {
      color: #093159;
      border: 2px solid #093159;
      border-radius: 5px;
      padding: 0;

      .ant-select-arrow {
        color: #093159;
      }
    }

    .integration {
      cursor: pointer;
    }
  }

  .tracking-switch.ant-switch.ant-switch-checked {
    background-color: #fff500;
    .ant-switch-handle {
      &:before {
        background-color: #05122d;
      }
    }
  }

  .tracking-switch.ant-switch {
    background-image: none;
    background-color: #6d6d6d;
    .ant-switch-handle {
      &:before {
        background-color: #ffffff;
      }
    }
  }
}

.analytic-success-display {
  display: flex;
  gap: 10px;
  justify-content: center;

  .analytic-display-text {
    align-self: center;
    cursor: pointer;
  }
    .analytic-display-text img {
      margin-right: 8px;
  }
}
