.ant-modal-footer {
  .ant-btn.ant-btn-default {
    border: 1px solid #77b8e0;
    color: #77b8e0;

    &:hover {
      color: #363636;
      border: 1px solid #b5b5b5;
    }
  }

  .ant-btn.ant-btn-primary {
    background-color: #77b8e0;
    color: #fff;
    border: 1px solid #77b8e0;

    &:hover {
      color: #363636;
      border: 1px solid #363636;
    }
  }
}

.ant-modal-header {
  border-bottom: none;

  .ant-modal-title {
    border-bottom: 1px solid #b9b9b9;
    font-family: "Open Sans", sans-serif;
    font-weight: bold;
    color: #093159;
    font-size: 24px;
  }
}

.ant-modal-footer {
  border-top: none;
}
