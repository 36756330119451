@import "../../css/_breakpoints.scss";

$primary-color: #05122d;

.website-board-header-popover.ant-popover {
  .ant-popover-inner {
    cursor: pointer;

    .ant-popover-inner-content {
      span {
        color: red;
      }
    }
  }
}

.ant-modal-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.website-edit-modal,
.website-delete-modal {
  .ant-modal-header {
    .ant-modal-title {
      p {
        font-family: "Manrope", sans-serif;
      }
    }
  }

  .ant-modal-body {
    font-family: "Inter", sans-serif;
    font-size: 16px;

    .website-edit-modal-name,
    .website-delete-modal-name {
      font-weight: bold;
    }

    .website-edit-modal-input {
      font-size: 16px;
    }

    .warning-message {
      color: red;
    }
  }

  .ant-modal-footer {
    //padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;

    text-align: left;

    .ant-btn.ant-btn-default {
      color: $primary-color;
      width: 200px;
      border: 1.5px solid #05122d;
      border-radius: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #05122d;
      }
    }

    .ant-btn.ant-btn-primary {
      background: #00fcb4;
      border-radius: 5px;
      width: 200px;
      margin-left: 20px !important;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;
      border: 1.5px solid #00fcb4;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #00fcb4;
      }
    }
  }
}

.website-edit-modal {
  .ant-input {
    padding: 10px 16px;
  }
}

@include media-breakpoint-only(xs) {
  .website-edit-modal,
  .website-delete-modal {
    .ant-modal-footer {
      .ant-btn.ant-btn-default,
      .ant-btn.ant-btn-primary {
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
