.data-table-website__header {
  margin-top: 25px;
  margin-bottom: 18px;

  .data-table-website__header__text {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 18px;
    color: #000;
  }
}
