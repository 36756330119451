@import "../../css/_breakpoints.scss";

$primary-button-color: #00fcb4;
$primary-color: #05122d;

.ant-btn.ant-btn-default.assign-vertical {
  background: #00fcb4;
  border-color: #00fcb4;
  border-radius: 5px;

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }
}

.assign-vertical-modal {
  .ant-modal-header {
    .ant-modal-title {
      font-family: "Manrope", sans-serif;
      padding-bottom: 20px;
    }
  }

  .ant-modal-body {
    font-family: "Inter", sans-serif;
    font-size: 16px;

    .ant-select {
      font-family: "Inter", sans-serif;
      font-size: 16px;
    }

    .warning-message {
      color: red;
    }
  }

  .ant-modal-footer {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    .ant-btn.ant-btn-default {
      border: 1px solid #77b8e0;
      color: $primary-color;
      width: 225px;
      border: 1.5px solid #05122d;
      border-radius: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #05122d;
      }
    }

    .ant-btn.ant-btn-primary {
      background: $primary-button-color;
      border-radius: 5px;
      width: 225px;
      margin-left: 20px !important;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;
      border: 1.5px solid $primary-button-color;

      &:hover {
        color: $primary-color;
        border: 1.5px solid $primary-button-color;
      }
    }
  }
}

@include media-breakpoint-only(xs) {
  .assign-vertical-modal {
    .ant-modal-footer {
      .ant-btn.ant-btn-default {
        width: 100%;
        margin-bottom: 10px;
      }

      .ant-btn.ant-btn-primary {
        width: 100%;
        margin-left: 0px !important;
      }
    }
  }
}
