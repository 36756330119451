$primary-text-color: #05122d;

.ecosystem-board-container {
  display: block;
  overflow: auto;
  border: 2px solid #ebebeb;
  border-radius: 7px;
  padding: 20px;
  background-color: #fff;
  margin-bottom: 10px;

  .ecosystem-name {
    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-right: auto;
    padding: 16px;
    color: #5a8efa;

    &:active,
    &:link,
    &:visited {
      text-decoration: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .ant-table-content {
    .ant-table-thead {
      .ant-table-cell {
        font-family: "Manrope";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        color: $primary-text-color;
        background-color: #f7faf9;
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        align-items: center;
        color: $primary-text-color;

        .cell-link {
          color: #5a8efa;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.ecosystem-board-header-popover.ant-popover {
  .ant-popover-inner {
    cursor: pointer;

    .ant-popover-inner-content {
      span {
        color: red;
      }
    }
  }
}

.ecosystem-edit-modal.ant-modal-wrap {
  font-size: 16px;

  .ecosystem-edit-modal-name, .ecosystem-edit-modal-industry, .ecosystem-edit-modal-currency {
    font-weight: bold;
    font-size: 16px;
  }

  .ecosystem-edit-modal-industry, .ecosystem-edit-modal-currency {
    margin-top: 15px;
  }

  .ecosystem-edit-modal-input, .ecosystem-edit-modal-industry-select, .ecosystem-edit-modal-currency-select {
    font-size: 16px;
    width: 100%;
  }

  .ecosystem-edit-modal-input.invalid-input {
    border: 2px solid red;
    box-shadow: none;
  }
}
