.button.is-success.signin-form-btn {
  font-family: "Inter", sans-serif;
  padding: 12px 20px;
  width: 149px;
  height: 42px;
  background: #00fcb4;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.forgot-password-link {
  font-weight: 400;
  float: right;
  color: #b3b0b0;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    color: #40a9ff;
  }
}
