.tracking-bar-chart-container {
  background-color: #fff;
  padding: 20px 40px;
  border: 1px solid #e6e6e6;
  border-radius: 5px;

  .text {
    font-family: "Manrope", sans-serif;
    margin-bottom: 10px;

    .title {
      font-size: 13pt;
      margin-bottom: 5px;
    }

    .description {
      font-size: 11pt;
      color: #7e8e9f;
    }
  }

  .chart {
    height: 400px;
  }
}
