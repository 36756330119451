$main-chart-filter-select-item-color: #05122d;
$main-chart-filter-date-range-color: #7e8e9f;

.main-chart-filter {
  .main-chart-filter-select {
    .ant-select-selector {
      .ant-select-selection-item {
        font-family: "Manrope", sans-serif;
        font-weight: 600;
        font-size: 18px;
        margin-right: 12px;
        color: $main-chart-filter-select-item-color;
      }
    }

    .ant-select-arrow {
      color: $main-chart-filter-select-item-color;
    }
  }

  .ant-col.main-chart-filter-date-range {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: $main-chart-filter-date-range-color;
  }
}
