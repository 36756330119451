.tracking-table {
  .ant-table-content {
    &::-webkit-scrollbar {
      height: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #dae4e8;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #093159;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #093159;
    }
  }

  .change-down {
    color: #c034a7;
  }
  .change-up {
    color: #25bea0;
  }

  .ant-table-thead {
    .ant-table-cell {
      font-family: "Manrope";
      font-weight: 700;
      line-height: 19px;
      color: #05122d;
      background-color: #fafafa;
      border-bottom: none;

      &:before {
        display: none;
      }
    }
  }

  .ant-table-tbody {
    .link {
      color: #5a8efa;
    }
  }

  .ant-table-ping-left:not(.ant-table-has-fix-left)
    .ant-table-container::before {
    box-shadow: none;
  }

  .ant-table-ping-right:not(.ant-table-has-fix-right)
    .ant-table-container::after {
    box-shadow: none;
  }
}
