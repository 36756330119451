.ant-alert.message-box {
  position: relative;
  background-color: #ffff99;
  padding: 15px 10px;
  border: none;

  a,
  .link {
    color: #5a8efa;
  }

  .link {
    cursor: pointer;
  }

  .ant-alert-close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }

  .ant-alert-message {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: #05122d;
    font-size: 12px;
  }
}
