@import "../../../css/breakpoints";

$primary-color: #05122d;

.tag-card-extra-popover.ant-popover-placement-bottom,
.tag-card-extra-popover.ant-popover-placement-bottomLeft,
.tag-card-extra-popover.ant-popover-placement-bottomRight {
  padding-top: 0 !important;
}

.ant-popover {
  .ant-popover-inner {
    .ant-popover-title {
      border-bottom: 1px solid #b9b9b9;
    }
  }
}

.edit-tag-modal {
  .ant-btn.ant-btn-default {
    border: 1px solid #77b8e0;
    color: #77b8e0;

    &:hover {
      color: #363636;
      border: 1px solid #b5b5b5;
    }
  }

  .ant-btn.ant-btn-primary {
    background-color: #77b8e0;
    color: #fff;
    border: 1px solid #77b8e0;

    &:hover {
      color: #363636;
    }
  }

  .ant-modal-header {
    border-bottom: none;

    .ant-modal-title {
      font-family: "Manrope", sans-serif;
      border-bottom: 1px solid #b9b9b9;
      color: black;
    }
  }

  .title {
    color: rgb(56, 51, 51);
  }

  .label {
    color: black;
  }

  .ant-modal-footer {
    border-top: none;
  }

  .ant-modal-body,
  .ant-modal-footer {
    font-family: "Inter", sans-serif;
  }

  .ant-modal-footer {
    //padding-left: 24px;
    //padding-right: 34px;
    //padding-bottom: 24px;

    .ant-btn.ant-btn-default {
      color: $primary-color;
      width: 172px;
      border: 1.5px solid #05122d;
      border-radius: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding-top: 12px;
      margin-right: 88px;
      padding-bottom: 12px;
      height: 42px;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #05122d;
      }
    }

    .ant-btn.ant-btn-primary {
      background: #00fcb4;
      border-radius: 5px;
      width: 172px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;
      border: 1.5px solid #00fcb4;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #00fcb4;
      }
    }
  }
}

.delete-tag-modal {
  .ant-btn.ant-btn-default {
    border: 1px solid #77b8e0;
    color: #77b8e0;

    &:hover {
      color: #363636;
      border: 1px solid #b5b5b5;
    }
  }

  .ant-btn.ant-btn-primary {
    background-color: #77b8e0;
    color: #fff;
    border: 1px solid #77b8e0;

    &:hover {
      color: #363636;
    }
  }

  .ant-modal-header {
    border-bottom: none;

    .ant-modal-title {
      font-family: "Manrope", sans-serif;
      border-bottom: 1px solid #b9b9b9;
    }
  }

  .ant-modal-footer {
    border-top: none;
  }

  .ant-modal-body,
  .ant-modal-footer {
    font-family: "Inter", sans-serif;
  }

  .ant-modal-footer {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;

    .ant-btn.ant-btn-default {
      border: 1px solid #77b8e0;
      color: $primary-color;
      width: 172px;
      border: 1.5px solid #05122d;
      border-radius: 5px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #05122d;
      }
    }

    .ant-btn.ant-btn-primary {
      background: #00fcb4;
      border-radius: 5px;
      width: 172px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      padding-top: 12px;
      padding-bottom: 12px;
      height: 42px;
      border: 1.5px solid #00fcb4;

      &:hover {
        color: $primary-color;
        border: 1.5px solid #00fcb4;
      }
    }
  }
}

@include media-breakpoint-only(xs) {
  .edit-tag-modal {
    .ant-modal-footer {
      .ant-btn.ant-btn-default {
        width: 100%;
        margin-bottom: 10px;
      }

      .ant-btn.ant-btn-primary {
        width: 100%;
        margin-left: 0px !important;
      }
    }
  }

  .delete-tag-modal {
    .ant-modal-footer {
      .ant-btn.ant-btn-default {
        width: 100%;
        margin-bottom: 10px;
      }

      .ant-btn.ant-btn-primary {
        width: 100%;
        margin-left: 0px !important;
      }
    }
  }
}
