.custom-tooltip {
  position: relative;
  background-color: #000;
  padding: 10px 20px;
  border-radius: 5px;
  line-height: 1;

  text-align: center;

  .price {
    color: #fff;
  }

  .price-change.positive {
    color: #00f9b2;
  }

  .price-change.negative {
    color: #fff500;
  }
}

.legend {
  margin-top: 30px;
  margin-left: 20px;
}

.recharts-surface {
  margin-left: -20px;
}
