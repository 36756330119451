$active-state-color: #00fcb4;

.website-column-list__checkbox {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $active-state-color;
    border-color: $active-state-color;
  }
  &:hover {
    .ant-checkbox-inner {
      border-color: $active-state-color;
    }
  }
  .ant-checkbox-checked {
    &:after {
      border-color: $active-state-color;
    }
  }
  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border-color: $active-state-color;
      }
    }
  }
  .ant-checkbox-input {
    &:focus + .ant-checkbox-inner {
      border-color: $active-state-color;
    }
  }

  .website-column-list__checkbox__item {
    font-family: "Manrope", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 36px;
    margin-bottom: 18px;
    color: #000;
  }
}
