@charset "utf-8";

// Colors
@import "colors";

// Bulma styles
@import "../../node_modules/bulma/css/bulma.css";

// Font Awesome
@import "../../node_modules/font-awesome/scss/font-awesome.scss";

// Custom styles
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $grey-dark;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  margin: 0;
  padding: 0;

  a:link {
    color: $white;
  }

  .navbar-dropdown a:link {
    color: $grey-darker;
  }

  @media screen and (max-width: 1023px) {
    .navbar-item a:link {
      color: $grey-darker;
    }
  }
}

input[type="checkbox"] {
  width: 17px;
  height: 17px;
  margin-bottom: 3px;
  vertical-align: middle;
}

#diffView {
  pre {
    background-color: initial;
    overflow-x: initial;
    padding: initial;
    white-space: pre-line;
    word-break: break-all;
    span {
      word-break: break-all;
    }
  }
}

.control {
  .icon {
    &.input-icon-clickable {
      pointer-events: initial;
    }
  }
}

.page-heading {
  border-bottom: 1px solid $grey-lighter;
}

.container-min-height {
  min-height: 300px;
}

.modal-padding {
  padding: 1.7rem;
}

.has-background-light-grey {
  background-color: #f7fbfa;
}

.modal-heading {
  border-bottom: 1px solid $grey-lighter;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
}
.modal-content {
  &.small-modal {
    width: 400px;
  }
}

.no-wrap {
  white-space: nowrap;
}

.is-align-items-middle {
  vertical-align: middle;
}

.is-clickable {
  cursor: pointer;
}

.tabs {
  ul {
    border-bottom: none;
    li {
      a {
        border-bottom-color: transparent;
        border-bottom-width: 3px;
        &:hover {
          border-bottom-color: transparent;
        }
      }
      &.is-active {
        a {
          border-bottom-width: 3px;
          &:hover {
            border-bottom-color: $link;
          }
        }
      }
    }
  }
}

.dropdown-date {
  border: none;
  background: none;
  padding: 0;
  box-shadow: none;
}

.webpage-iframe {
  border: 2px dashed $red;
}

.word-break {
  word-break: break-all;
}
.has-text-red {
  color: red;
}

.has-text-blue {
  color: #3c79ce;
}

.dropdown-seprate {
  border-bottom: 1px solid $grey-lighter;
}

.button-outlined-light-blue {
  color: #77b8e0;
  border: 1px solid #77b8e0;
}

.button-contained-light-blue {
  background-color: #4cb1f7;
  color: white;
  font-weight: bold;
}

.lightbox-min-height {
  min-height: 5rem;
}

.has-text-lightgray {
  color: lightgray;
}

.navbar.is-link {
  background-color: #3984ec;
  color: #fff;
}

.navbar.is-link .navbar-item.has-dropdown:focus .navbar-link,
.navbar.is-link .navbar-item.has-dropdown:hover .navbar-link,
.navbar.is-link .navbar-item.has-dropdown.is-active .navbar-link {
  background-color: #3984ec;
  color: #fff;
}

.tabs.toggle.toggle-rounded li > a {
  border-radius: 9999px;
}

.tabs.toggle li.is-active a {
  background-color: black;
  border-color: black;
  color: #fff;
  z-index: 1;
}

.spinner-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.purpleButton {
  background-color: #a42ee9;
  padding: 0.25rem 1rem;
  color: #fff;
  border-radius: 30px;
}

.lightBlueButton {
  background-color: #19c8e9;
  padding: 0.25rem 1rem;
  color: #fff;
  border-radius: 30px;
}

.yellowButton {
  background-color: #ee9c1c;
  padding: 0.25rem 1rem;
  color: #fff;
  border-radius: 30px;
}

.pinkButton {
  background-color: #fe65cb;
  padding: 0.25rem 1rem;
  color: #fff;
  border-radius: 30px;
}

.blueButton {
  background-color: #049ef7;
  padding: 0.25rem 1rem;
  color: #fff;
  border-radius: 30px;
}

.greenButton {
  background-color: #19e946;
  padding: 0.25rem 1rem;
  color: #fff;
  border-radius: 30px;
}

.greyButton {
  background-color: #8fad96;
  padding: 0.25rem 1rem;
  color: #fff;
  border-radius: 30px;
}

.lightGreenButton {
  background-color: #1ed086;
  padding: 0.25rem 1rem;
  color: #fff;
  border-radius: 30px;
}

.border-0 {
  border-width: 0 !important;
}
.width-column {
  width: 120px;
  margin: 0 0.5rem;
}
.productChangeList {
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.expanded table tbody tr:last-child td {
  border-bottom-width: 1px;
}

.seemore {
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: block;
}

.noChange {
  width: 100%;
  height: 30px;
  text-align: center;
  line-height: 30px;
  display: block;
  font-size: large;
}
.c-btnbox {
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  justify-content: center;
  line-height: 17px;
  margin-right: 6px;
  padding: 8px 9px;
  width: 120px;
  background-color: #f6f6f8;
}

.n-btnbox {
  background-color: #fff;
}

.website-name {
  font-weight: 400;
  line-height: 1.125;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.select-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
}

.right-side {
  max-height: 400px;
  overflow-y: scroll;
  box-sizing: border-box;
  margin: 0 0.5rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.card-profile {
  border-radius: 0.25rem;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  max-width: 100%;
  position: relative;
  max-width: 30%;
  max-height: 100%;
  margin: 2rem 0;
  border-radius: 35px;
  margin: 40px auto;
  padding: 10px;
  background-color: #fff;
}

.boder-line-bottom {
  border-bottom: 1px solid rgb(185, 183, 183);
}

.tdbreak {
  word-break: break-word;
}

.changeHistory td {
  line-height: 2.5;
}

.changeHistory th {
  background-color: lightgray;
}

// .revisionData {
//   table-layout: fixed;
// }

// .width-column {
//   max-width: 400px;
// }

.revisionData tr {
  height: 5rem;
}

.max-width-box {
  max-width: 40%;
  margin: auto;
}

.is-centered {
  text-align: center;
}

.forgot-password {
  font-weight: normal;
  float: right;
  color: #b3b0b0;
  font-size: 13px;
}

.width-plan-select {
  width: 50%;
}
.width-plan-select select {
  width: 100%;
}

.border-country {
  height: 2.5em;
  padding-left: calc(0.5em - 1px);
  position: relative;
  background-color: white;
  border-radius: 4px;
  color: #363636;
  min-width: 100%;
  font-size: medium;
  border: 1px solid #dbdbdb;
}
.border-country:hover {
  border-color: #b5b5b5;
}

.border-card {
  height: 2.5em;
  padding-top: calc(0.75em - 1px);
  position: relative;
  background-color: white;
  border-radius: 4px;
  color: #363636;
  border: 1px solid #dbdbdb;
  font-size: medium;
}
.border-card:hover {
  border-color: #b5b5b5;
}

.websiteBox-website {
  line-height: 1.125;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

[data-tooltip]:before {
  /* needed - do not touch */
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;

  /* customizable */
  transition: all 0.15s ease;
  padding: 10px;
  color: #333;
  border-radius: 10px;
  box-shadow: 2px 2px 1px silver;
}

[data-tooltip]:hover:before {
  /* needed - do not touch */
  opacity: 1;

  /* customizable */
  background: #659eef;
  margin-top: -50px;
  margin-left: 20px;
  color: white;
  font-size: 0.75em;
  z-index: 1;
}

.select-chart .text-chart {
  color: #232b2d;
  font-weight: bold;
  border: none;
  background-color: white;
  font-size: 1em;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
  max-width: 50%;
}

.navbar-item img {
  max-height: 2.75rem;
}
