.table.ant-table-wrapper {
  font-family: "Roboto", sans-serif;
  background-color: white;

  .ant-col{
    color: black;
  }
  .ant-table-tbody {
    .ant-table-row {
      .ant-table-cell.ant-table-row-expand-icon-cell.ant-table-cell-fix-left {
        width: 0px !important;
        padding: 0;
      }
    }
  }

  .text-no-changes {
    font-size: 16px;
    font-weight: bold;
    color: #093159;
    text-align: center;
  }

  .expand-data {
    border-radius: 5px;

    .view-button {
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      background-color: #093159;
      border-radius: 5px;
    }

    .close-button {
      color: #093159;
      font-size: 16px;
      font-weight: bold;
      border: 2px solid #6497ca;
      background-color: #dae8f5;
      border-radius: 5px;
    }

    .history-change-row {
      .field-name {
        border-radius: 5px;
        padding: 5px 20px;
        color: #fff;
      }

      .ant-space-item {
        &:last-child {
          background-color: #fff;
          margin: 0;
          padding: 0;
        }
      }
    }

    .ant-space-item {
      &:nth-last-child(1) {
        margin: auto;
        padding: 5px 0px;
      }
    }
  }

  .table-expand-row {
    .ant-table-expanded-row-fixed {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
