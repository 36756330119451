@import "../../css/colors";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");

$scoreboard-total-number-color: #4f5f6f;
$scoreboard-total-number-font-size: 21px;
$scoreboard-total-number-text-color: #7e8e9f;
$scoreboard-total-number-text-font-size: 13px;

.scoreboard-content-container {
  border: 1px solid $grey-lighter;
  background-color: $white;
  padding: 30px;
  text-align: center;

  .scoreboard-total-number-text {
    color: $scoreboard-total-number-text-color;
    font-size: $scoreboard-total-number-text-font-size;
    font-family: "Inter", sans-serif;
  }

  .scoreboard-total-number {
    color: $scoreboard-total-number-color;
    font-size: $scoreboard-total-number-font-size;
    font-family: "Manrope", sans-serif;
    font-weight: bold;
  }
}
