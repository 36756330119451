$selected-color: #00fcb4;

.text-filter-input {
  width: 150px;
  border-radius: 5px;
}

.text-filter-title {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #093159;
}

.ant-btn.text-filter-button {
  background-color: #093159;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
}

.multiple-filter-default-message {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #093159;
}

.multiple-filter-checkbox.ant-checkbox-wrapper {
  .ant-checkbox-checked .ant-checkbox-inner {
    border: 1px solid $selected-color;
    background-color: $selected-color;
  }
  &:hover {
    .ant-checkbox-inner {
      border: 1px solid $selected-color;
    }
  }
  .ant-checkbox-checked {
    &:after {
      border: 1px solid $selected-color;
    }
  }
  .ant-checkbox {
    &:hover {
      .ant-checkbox-inner {
        border: 1px solid $selected-color;
      }
    }
  }
  .ant-checkbox-input {
    &:focus + .ant-checkbox-inner {
      border: 1px solid $selected-color;
    }
  }
}
